'use strict'

###*
 # @ngdoc service
 # @name mundoData.factory:MessageData

 # @description

###
angular
  .module 'mundoData'
  .factory 'MessageData', [
    'Restangular'
    (Restangular) ->
      MessageDataBase = Restangular
        .service('messages')

      MessageDataBase
  ]
